import Vue from "vue";
import axios from "axios";

const axiosIns = axios.create({
  baseURL: "https://b2bapi.unuvar.com/api/",
});


Vue.prototype.$http = axiosIns;

export default axiosIns;
